/* Product Page Styles */
.product-page {
    padding: 30px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
  }
  
  /* Product Cards */
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
    margin: auto;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .product-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover img {
    transform: scale(1.05);
  }
  
  .product-card h3 {
    font-size: 1.4rem;
    color: #333;
    margin: 15px 0;
    font-weight: 500;
  }
  
  .product-card p {
    font-size: 1rem;
    color: #555;
  }
  
  .product-card .price {
    font-size: 1.2rem;
    color: #007bff;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .product-card button {
    padding: 12px 30px;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 20px;
  }
  
  .product-card button:hover {
    background-color: #e64a19;
    transform: translateY(-2px);
  }
  
  /* Back Button */
  .back-button {
    padding: 12px 25px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 30px;
    display: block;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  /* Filter/Search/Sort Styles */
.search-container, .filter-container, .sort-container {
    margin: 20px 0;
    text-align: center;
  }
  
  .search-container input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .filter-container select, .sort-container select {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 0 10px;
  }

  .quantity-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(10, 10, 10);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .quantity-modal h3 {
    margin-bottom: 15px;
    color: green;
  }
  
  .quantity-modal input {
    width: 60px;
    margin-bottom: 10px;
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .quantity-modal button {
    margin: 5px;
    padding: 8px 15px;
    border: none;
    background-color: #28a745;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .quantity-modal button.cancel {
    background-color: #dc3545;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 20px 0;
  }
  
  .pagination button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: rgb(241, 56, 56);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination button.active {
    background-color: #010912;
    color: rgb(177, 25, 25);
    border-color: #007bff;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: #007bff;
    color: white;
  }
  
  .pagination span {
    padding: 0 5px;
  }
  
  

  @media (max-width: 768px) {
    .product-list {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
  
    .search-container input,
    .filter-container select,
    .sort-container select {
      width: 100%;
    }
  }
  