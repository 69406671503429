/* General styling */
.App {
  text-align: center;
  background-color: rgb(1, 20, 45);
  font-family: Arial, sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header styling */
.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-header h1 {
  font-size: 2.5rem;
  margin: 0;
}

.App-header p {
  font-size: 1.2rem;
  margin: 10px 0;
}

/* Main content padding */
main {
  padding: 20px;
  margin-top: 80px; /* Ensure space for fixed navbar */
}

/* Link styling for the app */
.App-link {
  color: #61dafb;
}

/* Mobile responsive design */
@media (max-width: 768px) {
  /* Hide the navigation links on mobile */
  nav ul {
    display: none;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start; /* Align links to the left */
    padding-left: 20px; /* Align text in navbar */
  }
  

  /* Increase font size on mobile */
  nav ul li a {
    font-size: 1.3rem;
    padding: 10px 0;
  }

  /* Adjust header text size for mobile */
  .App-header h1 {
    font-size: 2rem;
  }

  .App-header p {
    font-size: 1rem;
  }

  /* Mobile-friendly margin for main content */
  main {
    padding: 10px;
  }

  /* Ensure content doesn't get hidden under navbar */
  body {
    padding-top: 80px; /* Adjust for mobile navbar */
  }
}

/* For even smaller screens, like phones in portrait mode */
@media (max-width: 680px) {
  .App-header h1 {
    font-size: 1.8rem;
  }

  .App-header p {
    font-size: 0.9rem;
  }
 

  /* Further reduce margins and padding */
  main {
    padding: 8px;
  }
}
