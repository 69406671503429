.bank-details {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .bank-details h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .bank-details p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .bank-details p:first-child {
    font-weight: bold;
    color: #222;
  }
  
  .bank-details p span {
    font-weight: 600;
    color: #007bff; /* Highlight color for values */
  }
  
  @media (max-width: 768px) {
    .bank-details {
      padding: 15px;
    }
  
    .bank-details p {
      font-size: 12px;
    }
  }
  