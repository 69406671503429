/* General Styles */
.category-container {
  padding: 20px;
  text-align: center;
  font-family: 'Poppins', Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 25px;
}

.category-card {
  background-color: #e8eff1;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #dce7eb;
}

.category-card h3 {
  color: #004085;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.category-card h3:hover {
  color: #0056b3;
}

.category-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 15px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.category-title:hover {
  color: #007bff;
}

.subcategory-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 25px;
  padding: 0;
  margin: 30px auto;
  list-style: none;
  max-width: 900px;
}

.subcategory-list li a {
  display: block;
  padding: 12px;
  color: #fff;
  background-color: #007bff;
  border-radius: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.subcategory-list li a:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.subcategory-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 25px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #444;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.subcategory-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background-color: #f3f9ff;
}

.subcategory-item .icon {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 12px;
}

.subcategory-item .title {
  font-size: 1.3rem;
  color: #2c3e50;
  margin-bottom: 8px;
  font-weight: 700;
}

.subcategory-item .description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Controls */
.controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.controls button {
  padding: 12px 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.controls button:hover {
  background-color: #0056b3;
  box-shadow: 0 5px 15px rgba(0, 91, 187, 0.4);
}

.controls button:focus {
  outline: none;
  box-shadow: 0 0 8px #0056b3;
}

.controls button:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

/* Media Queries */

/* Small screens (phones) */
@media (max-width: 576px) {
  .category-title {
    font-size: 1.2rem;
  }

  .subcategory-list {
    max-width: 100%;
  }

  .controls {
    flex-direction: column;
    gap: 10px;
  }

  .controls button {
    width: 100%;
  }
}

/* Medium screens (tablets) */
@media (max-width: 768px) {
  .category-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

/* Large screens (desktops) */
@media (min-width: 1200px) {
  .category-grid {
    gap: 35px;
  }

  .category-card {
    padding: 25px;
  }
}
