/* Universal Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Header Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003366; /* Dark blue */
  padding: 1rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

/* Logo Container */
.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 40px; /* Adjust size */
  height: auto;
  margin-right: 10px;
}

/* Centering Company Name */
.company-name-container {
  flex-grow: 1;
  text-align: center;
}

.company-name-container a {
  font-size: 1.5rem;
  color: #ffffff; /* White text */
  text-decoration: none;
  font-weight: bold;
}

/* Hamburger Button */
.hamburger {
  display: flex; /* Hidden by default */
  font-size: 1.5rem;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

/* Modal for Navigation */
.nav-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

/* Navigation Menu */
.nav {
  background-color: #c8ccd0; /* Light gray */
  padding: 2rem;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Close Button - Modal Top Right */
.close-btn {
  position: absolute;
  top: 15px; /* Adjust vertical distance from top of the modal */
  right: 20px; /* Adjust horizontal distance from right edge */
  font-size: 2rem;
  color: rgb(234, 29, 29); /* Red for visibility */
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2002; /* Ensure it stays on top of other content */
}


/* Links in the Menu */
.nav-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.nav-links a.cta-btn {
  background-color: #080a0d;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-align: center;
}

.nav-links a:hover {
  color: #007bff;
}

/* Cart Icon */
.cart-icon {
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}

.cart-icon-svg {
  width: 24px;
  height: 24px;
  color: #fff;
}

/* Responsive Design */

/* Medium Screens */
@media screen and (max-width: 992px) {
  .company-name-container a {
    font-size: 1.2rem;
  }

  .header {
    padding: 0.8rem 1.5rem;
  }

  .nav-links a {
    font-size: 0.9rem;
  }
}

/* Small Screens */
@media screen and (max-width: 768px) {
  .hamburger {
    display: block; /* Show the hamburger button */
  }

  .nav-links {
    display: flex; /* Hide nav links in header */
  }

  .close-btn {
    position: fixed; /* Fixed relative to the viewport */
    top: 15px; /* Distance from the top */
    right: 15px; /* Distance from the right */
    font-size: 2rem; /* Adjust size */
    color: rgb(234, 29, 29); /* Visibility with red color */
    z-index: 3000; /* Ensure it is on top */
  }

  .nav-modal {
    justify-content: flex-start; /* Align content to the top */
    padding-top: 1rem; /* Space below the close button */
  }

  .nav {
    border-radius: 0; /* Remove rounded edges for full-width appearance */
    width: 60%; /* Occupy more horizontal space */
    margin: auto;
  }

  .company-name-container {
    text-align: center; /* Ensure it stays centered */
    flex-grow: 1; /* Allow centering with extra space */
  }

  .header {
    flex-wrap: wrap;
    justify-content: center; /* Center the content */
    padding: 0.8rem 1rem;
  }

  .cart-icon {
    margin-left: 0; /* Align closer */
  }
}

/* Extra Small Screens */
@media screen and (max-width: 480px) {
  .logo-image {
    width: 30px; /* Reduce logo size */
  }

  .company-name-container a {
    font-size: 1rem; /* Smaller font for name */
  }

  .header {
    padding: 0.5rem 0.8rem;
  }

  .nav {
    width: 80%;
  }

  .nav-links a {
    font-size: 0.8rem;
  }

  .close-btn {
    position: fixed; /* Fixed relative to the viewport */
    top: 266px; /* Distance from the top */
    right: 45px; /* Distance from the right */
    font-size: 2rem; /* Adjust size */
    color: rgb(234, 29, 29); /* Visibility with red color */
    z-index: 3000; /* Ensure it is on top */
  }
}
