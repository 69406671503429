/* termsOfService.css */

/* General container for the Terms of Service page */
.terms-of-service-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    padding: 20px;
    background-color: #121212; /* Dark background for dark mode */
    color: #ffffff; /* Light text color for contrast */
  }
  
  /* Header section with the title of the page */
  header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  header h1 {
    font-size: 2.5em;
    color: #f39c12; /* Golden color for the title */
    margin: 0;
  }
  
  /* Content section of the Terms of Service */
  .terms-of-service-content {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #1e1e1e; /* Dark background for content */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
  }
  
  /* Section heading for each section */
  .terms-of-service-content h2 {
    font-size: 1.8em;
    color: #f39c12; /* Golden color for section headings */
    margin-top: 30px;
    border-bottom: 2px solid #f39c12;
    padding-bottom: 5px;
  }
  
  /* Paragraph styling for general content */
  .terms-of-service-content p {
    font-size: 1.1em;
    color: #dcdcdc; /* Lighter gray text for readability */
    margin-bottom: 20px;
    text-align: justify;
  }
  
  /* Links styled for better visibility */
  .terms-of-service-content a {
    color: #f39c12; /* Golden color for links */
    text-decoration: none;
  }
  
  .terms-of-service-content a:hover {
    text-decoration: underline;
  }
  
  /* Styling for unordered lists */
  .terms-of-service-content ul {
    padding-left: 20px;
  }
  
  .terms-of-service-content ul li {
    font-size: 1.1em;
    color: #dcdcdc; /* Lighter gray text for list items */
    margin-bottom: 10px;
  }
  
  /* Footer section */
  footer {
    text-align: center;
    margin-top: 40px;
    font-size: 1em;
    color: #888; /* Slightly lighter color for footer text */
  }
  
  footer p {
    margin: 0;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .terms-of-service-container {
      margin: 10px;
      padding: 15px;
    }
  
    .terms-of-service-content {
      padding: 20px;
    }
  
    header h1 {
      font-size: 2em;
    }
  
    .terms-of-service-content h2 {
      font-size: 1.6em;
    }
  
    .terms-of-service-content p {
      font-size: 1em;
    }
  
    footer p {
      font-size: 0.9em;
    }
  }
  