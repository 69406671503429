/* General Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }
  
  /* Product Details Container */
  .product-details-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
  }
  
  /* Product Overview Section */
  .product-overview {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .image-gallery {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .main-image {
    width: 100%;
    border-radius: 8px;
  }
  
  .thumbnail-gallery {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .thumbnail:hover {
    transform: scale(1.1);
  }
  
  .product-info {
    flex: 2;
  }
  
  .product-info h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .price-and-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 1.5rem;
    color: #e63946;
    font-weight: bold;
  }
  
  .rating {
    color: #ffcc00;
    font-weight: bold;
  }
  
  .description {
    margin-bottom: 20px;
    color: #666;
  }
  
  .variant-selector {
    margin-bottom: 20px;
  }
  
  .variant-selector h3 {
    margin-bottom: 10px;
  }
  
  .variant-button {
    margin-right: 10px;
    padding: 5px 15px;
    border: 2px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .variant-button:hover {
    background-color: #e8f4ff;
    border-color: #007bff;
  }
  
  .variant-button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #0056b3;
  }
  
  .purchase-options {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .quantity-selector label {
    margin-right: 10px;
  }
  
  .quantity-selector input {
    width: 50px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-to-cart-button,
  .wishlist-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-button:hover,
  .wishlist-button:hover {
    background-color: #0056b3;
  }
  
  .stock-info {
    margin-top: 10px;
    font-style: italic;
    color: #28a745;
  }
  
  /* Product Details Section */
  .product-details {
    margin-bottom: 30px;
  }
  
  .product-details h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #444;
  }
  
  .product-details ul {
    list-style: none;
    padding: 0;
  }
  
  .product-details li {
    margin-bottom: 10px;
  }
  
  /* Shipping Info Section */
  .shipping-info {
    margin-bottom: 30px;
  }
  
  .shipping-info h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #444;
  }
  
  .shipping-info p {
    margin: 5px 0;
  }
  
  /* Reviews Section */
  .reviews {
    margin-bottom: 30px;
  }
  
  .reviews h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #444;
  }
  
  .review {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  
  .review p {
    margin: 5px 0;
  }
  
  /* Related Products Section */
  .related-products {
    margin-bottom: 30px;
  }
  
  .related-products h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #444;
  }
  
  .related-products-grid {
    display: flex;
    gap: 20px;
  }
  
  .related-product-card {
    flex: 1;
    text-align: center;
  }
  
  .related-product-card img {
    width: 100%;
    border-radius: 10px;
  }
  
  .related-product-card p {
    margin: 5px 0;
  }
  
  /* FAQ Section */
  .faq {
    margin-bottom: 30px;
  }
  
  .faq h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #444;
  }
  
  .faq-item {
    margin-bottom: 10px;
  }
  
  .faq-item p {
    margin: 5px 0;
    line-height: 1.5;
  }
  