/* General styling for dark mode */
body {
    background-color: #121212;
    color: #f0f0f0;
    font-family: 'Arial', sans-serif;
  }
  
  /* Checkout container */
  .checkout-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  
  .bank-transfer-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px; /* Reduced padding */
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px; /* Reduced font size */
    border-radius: 3px; /* Reduced border radius */
    transition: background-color 0.3s ease;
  }
  
  .bank-transfer-button:hover {
    background-color: #45a049;
  }
  .shipping-fee {
    margin: 20px 0;
    padding: 15px;
    background-color: #f9f9f9; /* Light gray background */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 5px;
    font-size: 16px; /* Comfortable reading size */
    color: rgb(187, 19, 19); /* Dark text for visibility */
  }
  
  .shipping-fee h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #0056b3; /* Accent color for the header */
  }
  
  .shipping-fee p {
    margin-bottom: 10px;
    color: rgb(206, 184, 184); /* Dark text for visibility */

  }
  
  .shipping-fee a {
    color: #007bff; /* Blue link */
    text-decoration: none;
  }
  
  .shipping-fee a:hover {
    text-decoration: underline;
  }
  
  
  
  
  .checkout-container h2 {
    text-align: center;
    color: #76c7c0;
    margin-bottom: 20px;
  }
  
  /* Form styling */
  .checkout-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Form section */
  .form-section {
    background-color: #2a2a2a;
    padding: 13px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .form-section h3 {
    font-size: 1.5rem;
    color: #76c7c0;
    margin-bottom: 10px;
  }
  
  /* Label and input styles */
  label {
    display: block;
    margin-bottom: 10px;
    color: #f0f0f0;
    font-size: 1rem;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #333;
    color: #f0f0f0;
    font-size: 1rem;
  }
  
  input[type="radio"] {
    margin-right: 5px;
  }
  
  textarea {
    resize: vertical;
    height: 100px;
  }
  
  /* Order summary styling */
  .order-summary {
    margin-top: 20px;
  }
  
  .order-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #555;
    padding: 8px 0;
  }
  
  .order-item-name {
    color: #76c7c0;
    font-weight: bold;
  }
  
  .order-item-details {
    color: #f0f0f0;
  }
  
  /* Total amount styling */
  .total-amount h4 {
    margin-top: 15px;
    font-size: 1.25rem;
    color: #76c7c0;
  }
  
  /* Button styling */
  .place-order-button {
    background-color: #76c7c0;
    color: #121212;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    align-self: center;
  }
  
  .place-order-button:hover {
    background-color: #5eb5aa;
    box-shadow: 0 4px 8px rgba(118, 199, 192, 0.7);
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .checkout-form {
      gap: 15px;
    }
  
    .order-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .place-order-button {
      padding: 10px 15px;
    }
  }
  