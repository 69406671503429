/* General Styling */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

/* Header */
.about-header {
  text-align: center;
  margin-bottom: 30px;
}

.about-header h1 {
  font-size: 2.5em;
  color: #00e676; /* Neon Green */
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

.about-banner {
  width: 120px; /* Adjust for the desired logo size */
  height: auto;
  border-radius: 50%; /* Makes the logo circular */
  margin-top: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Adds a shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Section */
.about-section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #1f1f1f;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.about-section h2 {
  font-size: 2em;
  color: #00e676;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1.1em;
  line-height: 1.8;
  color: #bdbdbd;
}

/* Team Section */
.team-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.team-member {
  font-size: 1.2em;
  color: #00e676;
  cursor: pointer;
  margin: 10px 0;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.team-member:hover {
  color: #00c853;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1f1f1f;
  color: #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 1.8em;
  color: #00e676;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

.modal-content p {
  margin: 10px 0;
  font-size: 1.1em;
  color: #bdbdbd;
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #00e676;
  border: none;
  color: #121212;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease;
}

.close-button:hover {
  background: #00c853;
  color: #fff;
}

/* Links */
.about-section a {
  color: #00e676;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.about-section a:hover {
  color: #00c853;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

/* Footer */
.about-footer {
  text-align: center;
  margin-top: 30px;
  padding: 10px;
  background-color: #1f1f1f;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.about-footer p {
  font-size: 0.9em;
  color: #bdbdbd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
    padding: 15px;
  }

  .about-header h1 {
    font-size: 2em;
  }

  .about-section h2 {
    font-size: 1.5em;
  }

  .modal-content {
    padding: 15px;
  }

  .close-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
