/* General Styling */
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f9;
}

/* Container */
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Banner Styling */
.banner {
  background: linear-gradient(120deg, #00509e, #003b73);
  color: #fff;
  text-align: center;
  padding: 80px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.banner-content p{
  color: #df0d0d;
}

.banner::before {
  content: "";
  background: url('./banner-overlay.png') no-repeat center;
  background-size: cover;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 2;
  max-width: 700px;
  margin: 0 auto;
  color: #060606;
}

.collection-season {
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner h1 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.shop-now-button {
  background-color: #ffc107;
  color: #003b73;
  padding: 15px 30px;
  border: none;
  font-size: 18px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.shop-now-button:hover {
  background-color: #003b73;
  color: #ffc107;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

/* Categories Section */
.categories {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 50px 0;
  flex-wrap: wrap;
}

.category {
  flex: 1 1 calc(33.333% - 20px);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.category img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.category:hover img {
  transform: scale(1.1);
}

.category-name {
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0;
  background-color: #00509e;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Featured Products */
.featured-products {
  margin: 50px 0;
  text-align: center;
}

.featured-products h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #00509e;
}



.product-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.product-card {
  flex: 1 1 calc(25% - 20px);
  max-width: 240px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.product-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.product-info {
  padding: 15px;
  text-align: center;
}

.product-info h3 {
  font-size: 18px;
  font-weight: 700;
  color: #00509e;
  margin-bottom: 5px;
}

.product-info p {
  font-size: 16px;
  color: #555;
}

.why-choose-us {
  background-color: #f9f9f9;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.why-choose-us h2 {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.why-choose-us ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #b4b9ba;
}

.dropdown-container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: -1px;
  transition: background-color 0.3s ease;
}

.dropdown-title {
  display: flex;
  font-size: small;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 7px;
  background-color: #0e0d0d;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.dropdown-title:hover {
  background-color: #e0e0e0;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #007bff; /* Customize as needed */
  display: none;
}

.toggle-button.selected {
  color: #d8ded9; /* Green color for selected state */
  font-weight: bold; /* Bold text when selected */
  text-decoration: underline; /* Optional: underline to indicate selection */
}

.dropdown-content {
  padding: 10px;
  background-color: #060606;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 12px;
}

/* Our Partners Section */
.our-partners .partners-container {
  display: flex;
  gap: 0px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
  flex-wrap: wrap;
  margin-top: 10px;
}

.our-partners .partner {
  text-align: center;
  max-width: 180px;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 20px;
}

.our-partners .partner img {
  width: 120px; /* Adjust the size of the logos */
  height: auto;
  margin-bottom: 10px;
}

.our-partners .partner span {
  font-weight: bold;
  display: block;
  margin-top: 5px;
}

.our-partners p {
  font-size: 1.1rem;
  color: rgb(11, 11, 11); /* A softer gray color */
  margin-bottom: 30px;
}

/* Info Sections */
/* Info Sections */
.info-sections {
  margin: 60px 0;
  padding: 0 20px;
}

.info-sections h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #00509e;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.info-sections div {
  background: linear-gradient(135deg, #234b91, #eef2f6);
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin-bottom: 35px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.info-sections div:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.info-sections ul {
  margin: 20px 0;
  padding-left: 25px;
  list-style: none;
}

.info-sections li {
  font-size: 13px;
  color: #444;
  line-height: 1.6;
  margin-bottom: 10px;
  transition: color 0.3s;
}

.info-sections li:hover {
  color: #00509e;
}

.customer-reviews p {
  font-style: italic;
  font-size: 12px;
  color: #222;
  line-height: 1.8;
  border-left: 4px solid #00509e;
  padding-left: 15px;
  margin: 15px 0;
}

.delivery-returns p {
  font-size: 12px;
  line-height: 1.9;
  color: #333;
  margin: 10px 0;
  text-align: justify;
}
/* Responsive Design for All Screen Sizes */
@media (max-width: 1200px) {
  .home-container {
    padding: 10px;
  }

  .categories {
    gap: 15px;
  }

  .category {
    flex: 1 1 calc(50% - 15px);
  }

  .product-card {
    flex: 1 1 calc(33.333% - 15px);
  }
}

@media (max-width: 992px) {
  .banner h1 {
    font-size: 40px;
  }

  .shop-now-button {
    font-size: 16px;
    padding: 12px 25px;
  }

  .categories, .product-list {
    gap: 15px;
  }

  .category {
    flex: 1 1 calc(50% - 10px);
  }

  .product-card {
    flex: 1 1 calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .banner {
    padding: 60px 15px;
  }

  .banner h1 {
    font-size: 30px;
  }

  .banner-content {
    max-width: 90%;
  }

  .categories, .product-list {
    flex-direction: column;
    gap: 20px;
  }

  .category, .product-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .our-partners .partners-container {
    flex-direction: column;
    align-items: center;
  }

  .our-partners .partner {
    max-width: 200px;
  }

  .our-partners .partner img {
    width: 100px; /* Adjust image size on smaller screens */
  }

  .our-partners .partner span {
    font-size: 14px; /* Adjust font size for mobile */
  }
  .our-partners p {
    font-size: 1rem; /* Slightly smaller text for mobile */
  }
  

  

}

@media (max-width: 576px) {
  .banner {
    padding: 40px 10px;
  }

  .banner h1 {
    font-size: 24px;
  }

  .shop-now-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .product-card img {
    height: 100px;
  }

  .info-sections h2 {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .banner h1 {
    font-size: 20px;
  }

  .shop-now-button {
    padding: 8px 15px;
    font-size: 12px;
  }

  .featured-products h2 {
    font-size: 25px;
  }

  .why-choose-us {
    padding: 50px 15px;
  }

  .why-choose-us h2 {
    font-size: 24px;
  }

  .dropdown-container {
    padding: 1px; /* Add some padding for better visual spacing */
    width: 100%; /* Ensure it occupies full width of the parent */
    border-radius: 5px; /* Rounded corners for smooth edges */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for a floating effect */
    margin-top: 10px; /* Add margin to separate from other elements */
  }

  .dropdown-title h3 {
    font-size: 14px; /* Slightly larger text for better readability */
    font-weight: bold; /* Make title bold */
    color: #333; /* Dark color for contrast */
    margin: 0; /* Remove margin for better alignment */
  }

  .dropdown-content {
    font-size: 10px; /* Smaller font size for content */
    color: #555; /* Lighter text color for content */
    padding: 10px 0; /* Add vertical padding for spacing */
    line-height: 1.2; /* Better line spacing for readability */
  }

  .dropdown-content a {
    text-decoration: none; /* Remove underline */
    color: #007BFF; /* Color for links */
    transition: color 0.3s ease; /* Smooth color change on hover */
  }

  .dropdown-content a:hover {
    color: #0056b3; /* Darker color for hover effect */
  }


  .product-card {
    flex: 1 1 80%;
    max-width: 20px;
  }

  .product-card img {
    height: 60px;
  }

  .product-info h3 {
    font-size: 8px;
  }

  .product-info p {
    font-size: 6px;
  }

  .our-partners .partner img {
    width: 80px; /* Smaller logos on very small screens */
  }

  .our-partners .partner span {
    font-size: 12px; /* Smaller font size */
  }

  .our-partners p {
    font-size: 0.9rem; /* Even smaller text for very small screens */
  }

}

@media (max-width: 360px) {
  .banner {
    padding: 30px 5px;
  }

  .banner h1 {
    font-size: 18px;
  }

  .shop-now-button {
    font-size: 10px;
    padding: 6px 12px;
  }

  .product-card img {
    height: 120px;
  }

  .info-sections div {
    padding: 15px;
  }

  .info-sections li {
    font-size: 14px;
  }
}

