/* Admin Products Section */
.admin-products {
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
  }

  .product-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .delete-btn {
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .delete-btn:hover {
    background-color: #d9363e;
  }
  
  .message {
    color: green;
    margin: 10px 0;
    font-weight: bold;
  }
  
  
  /* Category Menu */
  .category-menu {
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
  }
  
  /* Category Item */
  .category {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
    color: #333;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
  }
  
  .category:hover {
    background-color: #e0e0e0;
  }
  
  /* Subcategory Menu */
  .subcategories {
    display: none;
    flex-direction: column;
    padding-left: 20px;
  }
  
  /* Subcategory Item */
  .subcategory {
    font-size: 16px;
    color: #555;
    padding: 8px 0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .subcategory:hover {
    background-color: #e0e0e0;
  }
  
  /* Show Subcategories When Category Is Active */
  .category.active + .subcategories {
    display: flex;
  }
  
  /* Active Category Style */
  .category.active {
    background-color: #007bff;
    color: white;
  }
  
  /* Heading Styles */
  h2 {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Form Layout */
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Form Field Layout */
  form div {
    display: flex;
    flex-direction: column;
  }
  
  /* Label Styles */
  label {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
  }
  
  /* Input Fields */
  input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  input:focus {
    border-color: #007bff;
  }
  
  /* Button Styles */
  button {
    padding: 12px;
    background-color: #28a745;
    color: white;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Paragraph Styles */
  p {
    margin-top: 20px;
    font-size: 1rem;
    text-align: center;
    color: #333;
  }
  
  /* Secondary Heading */
  h3 {
    margin-top: 40px;
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Preformatted Text */
  pre {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-top: 10px;
  }
  