/* cart.css */

/* General styling for dark mode */
body {
  background-color: #121212;
  color: #f0f0f0;
  font-family: 'Arial', sans-serif;
}

/* Container styling */
.cart-container {
  max-width: 900px;
  margin: 30px auto;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.cart-container h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #76c7c0;
}

/* Empty cart message */
.empty-cart {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
  color: #aaaaaa;
}

/* Cart items section */
.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

/* Individual cart item */
.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.cart-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
}

/* Product image styling */
.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #76c7c0;
}

/* Cart item details */
.cart-item-details {
  flex: 1;
  margin-left: 20px;
}

.cart-item-name {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 8px;
}

.cart-item-price {
  font-size: 1rem;
  color: #76c7c0;
  margin-bottom: 10px;
}

/* Quantity controls */
.cart-item-quantity {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-item-quantity button {
  width: 35px;
  height: 35px;
  background-color: #76c7c0;
  color: #121212;
  border: none;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.cart-item-quantity button:hover {
  background-color: #5eb5aa;
}

.cart-item-quantity button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.cart-item-quantity span {
  font-size: 1rem;
  font-weight: bold;
  color: #f0f0f0;
}

/* Remove item button */
.remove-item {
  background-color: #ff4d4f;
  color: #121212;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.remove-item:hover {
  background-color: #e63946;
  transform: scale(1.1);
}

/* Cart summary section */
.cart-summary {
  text-align: center;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.cart-summary h3 {
  font-size: 1.5rem;
  color: #76c7c0;
  margin-bottom: 20px;
}

.checkout-button {
  background-color: #76c7c0;
  color: #121212;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.checkout-button:hover {
  background-color: #5eb5aa;
  box-shadow: 0 4px 8px rgba(118, 199, 192, 0.7);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-details {
    margin-left: 0;
    margin-top: 10px;
  }

  .cart-item-quantity {
    justify-content: flex-start;
    gap: 5px;
  }
}
