/* General Container */
.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  color: #e0e0e0;
}

/* Title */
.contact-title {
  text-align: center;
  font-size: 2.5em;
  color: #00e676; /* Neon Green */
  margin-bottom: 20px;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

/* Buttons */
.contact-button {
  background-color: #1f1f1f;
  color: #00e676;
  border: 1px solid #00e676;
  padding: 12px 20px;
  border-radius: 6px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background-color: #00e676;
  color: #121212;
  border-color: #00c853;
  box-shadow: 0 0 15px #00e676, 0 0 30px #00c853;
}

/* Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-label {
  font-weight: bold;
  color: #e0e0e0;
  font-size: 1.1em;
}

.contact-input,
.contact-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 1em;
  background-color: #1f1f1f;
  color: #e0e0e0;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-input:focus,
.contact-textarea:focus {
  outline: none;
  border-color: #00e676;
  box-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

/* Textarea */
.contact-textarea {
  resize: vertical;
}

/* Status Message */
.form-status {
  text-align: center;
  color: #00e676;
  font-weight: bold;
  margin-top: 10px;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

/* Contact Details */
.contact-details {
  margin-top: 30px;
}

.contact-details h2 {
  color: #00e676;
  margin-bottom: 10px;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}

.contact-details p {
  color: #bdbdbd;
  margin: 5px 0;
}

.contact-address iframe {
  border: 0;
  border-radius: 8px;
  margin-top: 10px;
}

/* Social Media Links */
.social-media {
  text-align: center; /* Center the text and links */
}

.social-media .social-links {
  display: flex;
  justify-content: center; /* Center the links horizontally */
  gap: 15px;
}

.social-media .social-links div a {
  color: #00e676;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.social-media .social-links div a:hover {
  color: #00c853;
  text-shadow: 0 0 10px #00e676, 0 0 20px #00c853;
}


/* FAQ Section */
.faq-section details {
  margin-top: 15px;
}

.faq-section summary {
  font-weight: bold;
  color: #00e676;
  cursor: pointer;
}

.faq-section p {
  margin-left: 20px;
  color: #bdbdbd;
}

.faq-section a {
  color: #00e676;
  text-decoration: none;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    padding: 15px;
  }

  .contact-title {
    font-size: 2em;
  }

  .contact-button {
    font-size: 0.9em;
  }
}
