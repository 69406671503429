/* General Footer Styles */
.footer-container {
    background-color: #224b7a; /* Dark background */
    color: #f1f1f1; /* Light text */
    font-family: Arial, sans-serif;
    position: relative;
    padding: 10px 20px;
  }
  
  .footer-content {
    padding: 20px;
    display: none; /* Initially hidden */
    background-color: #333; /* Slightly lighter background */
    border-top: 2px solid #555; /* Separator line */
  }
  
  /* Section Styles */
  .footer-section {
    margin-bottom: 20px;
  }
  
  .footer-section h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #ffa500; /* Highlighted headings */
  }
  
  .footer-section p {
    margin: 0 0 10px;
    line-height: 1.6;
    font-size: 0.9em;
    color: #f1f1f1;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  
  .footer-section ul li a {
    color: #f1f1f1;
    text-decoration: none;
    font-size: 0.9em;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #ffa500; /* Highlighted link on hover */
  }
  
  /* Contact Info & Social Media */
  .footer-section .social-media a {
    display: inline-block;
    margin-right: 10px;
  }
  
  .footer-section .social-media img {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1); /* Invert for light on dark */
    transition: transform 0.3s, filter 0.3s;
  }
  
  .footer-section .social-media img:hover {
    transform: scale(1.2); /* Slight zoom on hover */
    filter: brightness(1) invert(0); /* Revert on hover */
  }
  
  /* Footer Bottom */
  .footer-bottom {
    text-align: center;
    font-size: 0.8em;
    margin-top: 10px;
    border-top: 1px solid #555;
    padding-top: 10px;
    color: #bbb;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  /* Toggle Button */
  .footer-toggle {
    position: absolute;
    bottom: 5px;
    right: 10px;
    background-color: #ffa500;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .footer-toggle:hover {
    background-color: #ff8c00;
  }
  