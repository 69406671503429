.catalogue-wrapper {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .catalogue-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .catalogue-search {
    padding: 10px;
    width: 60%;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .catalogue-sort {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .catalogue-loading {
    text-align: center;
    font-size: 1.2em;
    color: #888;
  }
  
  .catalogue-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 10px 15px;
    margin: 0 5px;
    border: none;
    border-radius: 4px;
    background-color: #3498db;
    color: white;
    cursor: pointer;
  }
  
  .pagination-button.active {
    background-color: #2980b9;
    font-weight: bold;
  }
  
  .pagination-button:hover {
    background-color: #1f6396;
  }
  