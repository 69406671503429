.form-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #333; /* Dark background for the container */
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1); /* Lighter shadow for contrast */
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #555; /* Darker border for input fields */
    border-radius: 5px;
    background-color: #444; /* Dark input field background */
    color: white; /* White text on dark background */
}

.form-button {
    padding: 8px;
    font-size: 14px;
    border: none;
    border-radius: 50px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.facebook-login {
    margin: 10px 15px;
    padding: 8px 15px;
    font-size: 12px;
    width: 40%;
    background-color: #4267B2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.instagram-login {
    margin: 10px 15px;
    padding: 8px 15px;
    font-size: 12px;
    width: 40%;
    background-color: #E1306C;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.instagram-login:hover {
    background-color: #b82556;
}

.facebook-login:hover {
    background-color: #365899;
}

.form-error {
    color: #ff6347; /* Light red for error messages */
    font-size: 14px;
    margin-bottom: 10px;
}

.form-toggle {
    margin-top: 15px;
}

.toggle-button {
    margin-left: 5px;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}
