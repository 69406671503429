/* privacy-policy.css */

/* General container for the Privacy Policy page */
.privacy-policy-container {
    font-family: Arial, sans-serif;
    color: #ffffff;
    background-color: #121212; /* Dark background for dark mode */
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
    max-width: 900px;
    margin: 0 auto;
    transition: all 0.3s ease;
  }
  
  /* Header section */
  header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  header h1 {
    font-size: 2.5em;
    font-weight: bold;
    color: #f39c12; /* Golden color for header */
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: color 0.3s ease;
  }
  
  header h1:hover {
    color: #e67e22;
  }
  
  /* Content Section */
  .privacy-policy-content {
    line-height: 1.8;
    color: #bdc3c7; /* Light gray text for readability */
    font-size: 1.1em;
  }
  
  .privacy-policy-content p {
    margin-bottom: 20px;
  }
  
  .privacy-policy-content ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content ul li {
    padding-left: 15px;
    position: relative;
  }
  
  .privacy-policy-content ul li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #f39c12; /* Golden bullet points */
    font-size: 1.5em;
    top: 0;
  }
  
  .privacy-policy-content a {
    color: #f39c12; /* Golden links */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .privacy-policy-content a:hover {
    color: #e67e22;
  }
  
  /* Section Titles */
  h2 {
    font-size: 1.6em;
    font-weight: bold;
    color: #f39c12; /* Golden section titles */
    margin-top: 40px;
    margin-bottom: 15px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  
  h2:hover {
    color: #e67e22;
  }
  
  /* Subsection for specific sections */
  h3 {
    font-size: 1.3em;
    font-weight: 600;
    color: #f39c12; /* Golden subsection titles */
    margin-top: 30px;
  }
  
  h3:hover {
    color: #e67e22;
  }
  
  /* Contact information section */
  .privacy-policy-content p a {
    font-weight: bold;
    text-decoration: underline;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 30px 15px;
    }
  
    header h1 {
      font-size: 2em;
    }
  
    .privacy-policy-content {
      font-size: 1em;
    }
  
    .privacy-policy-content ul li {
      padding-left: 10px;
    }
  
    .privacy-policy-content ul li::before {
      font-size: 1.2em;
    }
  }
  
  /* Smooth Scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  /* Fade-In Animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .privacy-policy-container {
    animation: fadeIn 1.5s ease-out;
  }
  
  /* Subtle Hover Effect for Links */
  a {
    position: relative;
    display: inline-block;
  }
  
  a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #f39c12; /* Golden underline */
    bottom: -5px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }
  
  a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  /* Light/Obscure Background effect */
  .privacy-policy-container {
    background: linear-gradient(to bottom, #34495e, #2c3e50);
    color: #ecf0f1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Adding a subtle glow effect to certain text */
  h1, h2, h3 {
    text-shadow: 0 0 10px rgba(243, 156, 18, 0.5);
  }
  
  /* Paragraphs with links */
  p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  /* Add space between sections */
  .privacy-policy-content > p, 
  .privacy-policy-content > ul {
    margin-bottom: 30px;
  }
  
  /* Footer section */
  footer {
    margin-top: 40px;
    text-align: center;
    color: #bdc3c7;
    font-size: 1em;
    padding: 10px;
  }
  
  footer p {
    font-size: 0.9em;
  }
  